import React, { useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import store from '../store';
import { Helmet } from 'react-helmet';
import { InteractivePI } from '../pi/InteractivePI';
import { GlobalStyle, NormalizeGlobalStyle } from '../components/global';
import ScrollLockObserver from '../components/ScrollLockObserver';
import favicon from '../assets/favicon.ico';
import { ga4Content } from '../util/data/ga4';

/**
 * Import ProximaNova `@font-face` declarations into our main layout.
 * Make sure to include this CSS file into any new layouts we may make.
 * This is important that this is a real CSS file rather than a
 * styled-component `createGlobalStyle()` call.
 *
 * When using the JS version, we'd see flickering of our fonts when interacting
 * with some functional component on the page (expanding sticky ISI, toggling main nav, etc.).
 * By using a real CSS file, we don't run into any of those re-rendering / flickering issues.
 */
import '../fonts/proxima-nova-font-faces.css';

function InteractivePILayout({ children, pageMeta, location, piKey }) {

    const data = useStaticQuery(graphql`
      {
        site {
          siteMetadata {
            title
            description
            siteURL
          }
        }
        piContent {
          PIData
        }
      }
    `);

    const { siteURL } = data.site.siteMetadata;
    const allPIsDataRawStr = data.piContent.PIData;
    // we have some work to do in the pi data that is passed thought the component
    // the data comes though the qraphql query as a string, so we need to convert it 
    // to an object. Also, the data will have different properties depending on how many 
    // xml files like in `pi-xml` in the project root. The `piKey` prop is what enables 
    // this selection.

    // TODO: add error handling and some sort of default selection feature here using values
    // from constants above: SHOW_INTERACTIVE_PI, CURRENT_SELECTED_PI, PI_PDF_URL
    const allPIsDataObj = JSON.parse(allPIsDataRawStr);
    const selectedPIDataObj = allPIsDataObj[piKey];
    //console.log(allPIsDataObj);
    return (
        <>
            <Helmet
              htmlAttributes={{
                lang: 'en',
              }}
            >
                <meta charSet="utf-8" />
                <title>{pageMeta ? pageMeta.title : ''}</title>
                <meta name="description" content={pageMeta ? pageMeta.description : ''} />
                <meta name="keywords" content={pageMeta ? pageMeta.keywords : ''} />
                <link rel="canonical" href={`${siteURL}${location ? location.pathname : ''}`} />
                <link rel="icon" href={favicon} type="image/x-icon"></link>
                { pageMeta && pageMeta.noindex && (
                    <meta name="robots" content="noindex" />
                )}
                {/* OneTrust Cookies Consent  */}
                <script
                    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script={
                        process.env.GATSBY_PRODUCTION_BUILD
                            ? 'fc3238a9-c92f-40bc-a84b-b8db8222b47f'
                            : '0193ce4d-f563-70df-88f7-a9b4b1276b23-test'
                    }
                ></script>
                <script type="text/javascript">{`function OptanonWrapper() { }`}</script>
                {/* OneTrust Cookies Consent end */}

                {/* dataLayer initialization for properly capturing page url on entry */}
                <script type="text/javascript">{`
                    window.dataLayer = window.dataLayer || []; 
                    window.dataLayer.push({
                        event: "initial_page_load",
                        page_url: window.location.href.replace(window.location.origin, '')
                    });
                `}</script>
                {/* end dataLayer initialization for properly capturing page url on entry */}

                {/* begin JSON-LD page info */}
                {/* Because Gatsby + Helmet are like helicopter parents, we can't just plop the 
                all the schema tags in one chunk. So we have to do it in this ugly way  */}
                {pageMeta && pageMeta.schemaJsonLD && pageMeta.schemaJsonLD.map(script => <script type="application/ld+json">{script}</script>)}                
                {/* end JSON-LD */}

                {/* Google Tag Manager */}
                <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-NMH84D3');`}</script>
                {/* End Google Tag Manager */}

                 {/* GA4 Taxonomy */}
                 <script type="text/javascript">
                    {`
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({"ga4": {"ga4_page_audience":"HCP","ga4_page_customer_journey_stage":"Consideration","ga4_page_customer_specialty":"Oncology","ga4_page_material_intent":"","ga4_page_product":"KEYTRUDA","ga4_page_region":"US","ga4_page_cmsplatform":"S3","ga4_page_cmstheme":"custom","ga4_page_country":"United States","ga4_page_language":"English","ga4_page_indication":"PAN TUMOR"}});
                    `}
                </script>
                {/* End GA4 Taxonomy */}

                {/* <style>{`html {scroll-behavior: smooth;}`}</style> */}
                <style>{`html, body {background-color: #f3f3f3;}`}</style>
                <style>{`html { scroll-behavior: smooth;} @media screen and (prefers-reduced-motion: reduce) { html { scroll-behavior: auto; } }`}</style>
                
            </Helmet>
            {/* Keep global styles outside the Provider, they should never re-render */}
            <NormalizeGlobalStyle />
            <GlobalStyle />
            {/**
             * Ensure that the Redux `Provider` is at (or near) the top of our component tree
             * so that child components can access the store.
             */}
            <Provider store={store}>
                <ScrollLockObserver />
                {/* There may be a better way to do this...we can see in the future */}
                <InteractivePI piData={selectedPIDataObj} piKey={piKey} />
            </Provider>
        </>
    );
}

export default InteractivePILayout;
